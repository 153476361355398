"use client";
import Footer from "@/components/LandingPage/Footer/Footer";
import FooterMobile from "@/components/LandingPage/Footer/FooterMobile";
import Navbar from "@/components/LandingPage/Navbar/Navbar";
import NavbarMobile from "@/components/LandingPage/Navbar/NavbarMobile";
import useWindowSize from "@/helper/useWindowSize";
import Image from "next/image";
import { useEffect, useState } from "react";
import ToTopImage from "../../assets/backtotop.svg";
import useLanguage from "src/context/useLanguage";
import SecondNavBar from "@/components/LandingPage/Navbar/SecondNavBar";
import appStore from "../../assets/appstore.svg";
import amazon from "../../assets/amazon.svg";
import android from "../../assets/GooglePlay.svg";
import SecondNawBarMobile from "@/components/LandingPage/Navbar/SecondNawBarMobile";

export default function DownloadApp() {
  const { width } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { language, translations } = useLanguage();

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY || document.documentElement.scrollTop;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleAmazonClick = () => {
    window.open(
      "https://www.amazon.com/Sabika/dp/B0DPCSVN4J/ref=sr_1_1?crid=U7K77NJQB19B&dib=eyJ2IjoiMSJ9.SezMX_pouNba3YikJlOQ1RPe-eD_UgI7adL8CkD2GryqjPo0udk4Cc7mn88PPJzOpn4HCKWnfnTRpdUDzTM-0A.bHnSpuf6tQhvtm_boArEG47kPhSj61obwhmUYGCJTNA&dib_tag=se&keywords=Sabika+app&qid=1734221302&sprefix=sabila+a%2Caps%2C604&sr=8-1",
      "_blank"
    );
  };
  const handleAppStoreClick = () => {
    window.open("https://apps.apple.com/eg/app/sabika-سبيكة/id6501984640");
  };
  const handlePlayStoreClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.sabika.app&hl=en"
    );
  };
  return (
    <main className="overflow-hidden">
      {width <= 640 ? (
        <div className="relative sm:hidden">
          <div
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            className={`fixed right-0 bottom-0 animate-bounce cursor-pointer ${
              scrollPosition < 905 ? "hidden" : "block"
            }`}
          >
            <Image src={ToTopImage} alt="ToTopImage" width={75} />
          </div>
          <SecondNawBarMobile isOpen={isOpen} setIsOpen={setIsOpen} />
          {isOpen === false ? (
            <>
              <div
                dir={language === "en" ? "ltr" : "rtl"}
                className=" sm:flex sm:flex-col sm:min-h-screen"
              >
                <div className="flex-grow mt-8 pb-24 w-4/5 mx-auto">
                  {language === "en" ? (
                    <div className="mx-auto font-ibmArabic-400">
                      <p className="text-2xl font-ibmArabic-400 text-[#333333]">
                        Discover{" "}
                        <span className="font-ibmArabic-600 text-[#e9c237]">
                          Smarter Ways
                          <br />
                        </span>
                        To{" "}
                        <span className="font-ibmArabic-600 text-[#e9c237]">
                          {" "}
                          Manage Gold!
                        </span>
                      </p>
                      <p className="text-base text-[#333333] mt-8">
                        <span className="font-ibmArabic-600 text-[#e9c237]">
                          Sabika{" "}
                        </span>
                        is now available on the{" "}
                        <span className="font-ibmArabic-600 text-[#e9c237]">
                          Apple App Store (iOS), Google Play (Android), the web,
                          and the Amazon Appstore,
                        </span>{" "}
                        offering you a seamless and secure experience to buy,
                        sell, and manage your{" "}
                        <span className="font-ibmArabic-600 text-[#e9c237]">
                          gold fractions.
                        </span>{" "}
                        <br />
                        <br />
                      </p>
                      <div className="flex flex-row items-center gap-3 mt-2">
                        <Image
                          onClick={handleAppStoreClick}
                          src={appStore}
                          alt="appStore"
                          loading="eager"
                          className="cursor-pointer"
                        />
                        <Image
                          onClick={handlePlayStoreClick}
                          src={android}
                          alt="android"
                          loading="eager"
                          className="cursor-pointer"
                          width={135}
                        />
                      </div>
                      <Image
                        onClick={handleAmazonClick}
                        src={amazon}
                        alt="amazon"
                        loading="eager"
                        className="cursor-pointer mt-6"
                      />
                      {/* <p className="font-ibmArabic-400 text-[#4C4C4C] mt-8 text-base">
                        Download the Android app (APK file) directly from the
                        following link :
                      </p> */}
                    </div>
                  ) : (
                    <div className="mx-auto font-ibmArabic-400">
                      <div>
                        <p className="">
                          {translations[language].section2.StayTunedfor}{" "}
                          <p className="text-2xl font-ibmArabic-400">
                            اكتشف طرقًا أكثر{" "}
                            <span className="text-[#e9c237] font-ibmArabic-600">
                              {" "}
                              ذكاءً{" "}
                            </span>
                            لإدارة{" "}
                            <span className="text-[#e9c237] font-ibmArabic-600">
                              الذهب!
                            </span>
                          </p>
                        </p>
                        <p className="text-lg mt-8">
                          سبيكة متاحة الآن على متجر أبل للتطبيقات (iOS)، و متجر
                          جوجل للتطبيقات (Android)، وعلى الويب، وعلى متجر أمازون
                          للتطبيقات، مما يوفر لك تجربة سلسة وآمنة لشراء، بيع،
                          وإدارة الذهب الخاص بك.
                          <br />
                          <br />
                        </p>
                        <div className="flex flex-row items-center gap-3 mt-2">
                          <Image
                            onClick={handleAppStoreClick}
                            src={appStore}
                            alt="appStore"
                            loading="eager"
                            className="cursor-pointer"
                          />
                          <Image
                            onClick={handlePlayStoreClick}
                            src={android}
                            alt="android"
                            loading="eager"
                            className="cursor-pointer "
                            width={135}
                          />
                        </div>
                        {/* <p className="font-ibmArabic-400 text-[#4C4C4C] mt-8 text-base">
                          قم بتحميل تطبيق الأندرويد (ملف APK) مباشرة من الرابط
                          التالي :
                        </p> */}
                        <Image
                          onClick={handleAmazonClick}
                          src={amazon}
                          alt="amazon"
                          loading="eager"
                          className="cursor-pointer mt-6"
                        />
                        {/* <Image
                          onClick={handlePlayStoreClick}
                          src={android}
                          alt="android"
                          loading="eager"
                          className="cursor-pointer mt-6"
                        /> */}
                      </div>
                    </div>
                  )}
                </div>
                {/* <DynamicPrivacyAndPolicy /> */}
              </div>
              <FooterMobile />
            </>
          ) : (
            <div className="h-screen bg-white"></div>
          )}
        </div>
      ) : (
        <div className="relative hidden sm:flex sm:flex-col sm:min-h-screen">
          <div
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            className={`fixed right-0 bottom-0 animate-bounce cursor-pointer ${
              scrollPosition < 740 ? "hidden" : "block"
            }`}
          >
            <Image src={ToTopImage} alt="ToTopImage" />
          </div>

          <div className="flex-none">
            <SecondNavBar />
          </div>
          <div
            dir={language === "en" ? "ltr" : "rtl"}
            className="flex-grow mt-24 pb-24"
          >
            {language === "en" ? (
              <div className="w-2/3 mx-auto font-ibm-400">
                <p className="text-6xl font-ibmArabic-400 text-[#333333]">
                  Discover{" "}
                  <span className="font-ibmArabic-600 text-[#e9c237]">
                    Smarter Ways
                    <br />
                  </span>
                  To{" "}
                  <span className="font-ibmArabic-600 text-[#e9c237]">
                    {" "}
                    Manage Gold!
                  </span>
                </p>
                <p className="text-2xl mt-8">
                  <span className="font-ibmArabic-600 text-[#e9c237]">
                    Sabika{" "}
                  </span>
                  is now available on the{" "}
                  <span className="font-ibmArabic-600 text-[#e9c237]">
                    Apple App Store (iOS), Play Store (Android), the web, and
                    the Amazon Appstore,
                  </span>{" "}
                  offering you a seamless and secure experience to buy, sell,
                  and manage your{" "}
                  <span className="font-ibmArabic-600 text-[#e9c237]">
                    gold fractions.
                  </span>{" "}
                  <br />
                  <br />
                </p>
                <div className="flex flex-row items-center gap-3 mt-2">
                  <Image
                    onClick={handleAppStoreClick}
                    src={appStore}
                    alt="appStore"
                    loading="eager"
                    className="cursor-pointer"
                  />
                  <Image
                    onClick={handlePlayStoreClick}
                    src={android}
                    alt="android"
                    loading="eager"
                    className="cursor-pointer"
                    width={135}
                  />
                </div>
                {/* <p className="font-ibmArabic-400 text-[#4C4C4C] mt-8 text-base">
                  Download the Android app (APK file) directly from the
                  following link :
                </p> */}
                <Image
                  onClick={handleAmazonClick}
                  src={amazon}
                  alt="amazon"
                  loading="eager"
                  className="cursor-pointer mt-6"
                />
                {/* <Image
                  onClick={handlePlayStoreClick}
                  src={android}
                  alt="android"
                  loading="eager"
                  className="cursor-pointer mt-6"
                /> */}
              </div>
            ) : (
              <div className="w-2/3 mx-auto font-ibmArabic-400">
                <div className="flex flex-col items-start gap-[4.4rem] mt-10 text-5xl font-ibmArabic  font-normal leading-[4rem]">
                  <div>
                    <p className="">
                      {translations[language].section2.StayTunedfor}{" "}
                      <p>
                        اكتشف طرقًا أكثر{" "}
                        <span className="text-[#e9c237]"> ذكاءً </span>
                        لإدارة <span className="text-[#e9c237]">الذهب!</span>
                      </p>
                    </p>
                    <p className="text-lg mt-8">
                      سبيكة متاحة الآن على متجر أبل للتطبيقات (iOS)، وعلى أجهزة
                      الأندرويد (Android)، وعلى الويب، وعلى متجر أمازون
                      للتطبيقات، مما يوفر لك تجربة سلسة وآمنة لشراء، بيع، وإدارة
                      الذهب الخاص بك.
                      <br />
                      <br />
                    </p>
                    <div className="flex flex-row items-center gap-3 mt-2">
                      <Image
                        onClick={handleAppStoreClick}
                        src={appStore}
                        alt="appStore"
                        loading="eager"
                        className="cursor-pointer"
                      />
                      <Image
                        onClick={handleAmazonClick}
                        src={amazon}
                        alt="amazon"
                        loading="eager"
                        className="cursor-pointer"
                      />
                    </div>
                    <p className="font-ibmArabic-400 text-[#4C4C4C] mt-8 text-base">
                      قم بتحميل تطبيق الأندرويد (ملف APK) مباشرة من الرابط
                      التالي :
                    </p>
                    <Image
                      onClick={handlePlayStoreClick}
                      src={android}
                      alt="android"
                      loading="eager"
                      className="cursor-pointer mt-6"
                      width={135}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex-none">
            <Footer />
          </div>
        </div>
      )}
    </main>
  );
}
